.facsimile-container {
    border: 2px solid black;
    width: 50%;
    height: 200px;
  }
  .facsimile-container input {
    border: 2px solid black;
    margin-left: 20px;
    margin-top: 20px;
    display: block;
  }
  #photo-upload{
    display: none;
  }