.momentContainer {
  max-width: 60rem;
  width: 100%;
}

.momentUploads {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.avatar {
  width: 2.25rem;
  height: 2.25rem;
}

.user-input-div {
  border: 2px solid var(--primary-color);
  padding: .75rem;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-radius: 0.375rem;
}
.user-input-div span {
  margin-top: 7px;
}
.user-input-div:hover {
  background: var(--primary-color);
  color: #fff;
}


.user-input-div img {
  height: 1.75rem;
  width: auto;
  margin-bottom: 0.4rem;
}

.disable-resource-div {
  opacity: 0.6;
  background: #eee;
  border: 2px solid #eee;
}

.disable-resource-div:hover {
  cursor: not-allowed;
  background: #eee;
  color: var(--primary-color)
}

.momentForm {
  display: Flex;
}

.momentForm .form-field {
  flex: 1;
  padding-right: 1rem;
  margin-bottom: 0;
}

.resourceContainer {
  margin-top: 0rem;
}

.resourceContainer h2 {
  font-size: 1.125rem;
  text-align: center;
  margin: 2rem 0 2rem;
}

.momentResource-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

@media only screen and (max-width:900px) {
  .momentResource-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width:700px) {
  .momentResource-container {
    grid-template-columns: 1fr 1fr;
  }
}


.modal-upload-div {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  background: #f6f6f6
}

.modal-upload-div:hover {
  cursor: pointer;
}
.show-on-desktop {
  display: none !important;
}

@media only screen and (min-width:900px) {
  .show-on-desktop {
    display: flex !important;
  }
}
.moments-name {
  font-weight: 500;
  font-size: 15px;
  /* line-height: 15px; */
  color: var(--inputBorder-color);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width:300px; 
  width: 300px; 
}
.create-moment-btn {
  background: black !important;
  color: white;
  font-weight: 400 !important;
  /* width: 100%; */
}
/* Dark Grey 
.moment-border {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 17px;
}


.chat-container {
  all: unset;
}

.chat-side-item {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--inputBorder-color);
}
.new-chat {
  border: 1px solid var(--inputBorder-color);
  border-radius: 4px;
  padding: 12px;
}

.chat-side-item-down {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: var(--inputBorder-color);
  border-radius: 4px;
}
.bg-light-gray {
  background: #f6f6f6;
}
.moment-text {
  max-width: 55rem;
  width:100%;
  margin:0 auto;
}
.moment-text p {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.toggle-chat {
  border: 1px solid black;
  border-radius: 100px;
}
.date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3c3c46;
}

.mb-16 {
  margin-bottom: 16px;
}
.moment-span span {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  color: var(--inputBorder-color);
}
.moment-span {
  border-bottom: 1px solid var(--border-color);
}

.moment-span2 {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;


  color: var(--inputBorder-color);
}
.active-resource-div {
  background-color: #d8d4d4 !important;
}
.disable-resource-div {
  cursor: not-allowed;
}
.active-resource-div span {
  font-weight: 600;
}

.moment-span2 input {
  width: 100%;
  height: 50px;
  background: #ffffff;

  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}

.modal-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 48px;

  color: var(--inputBorder-color);
}
.body-div span {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--inputBorder-color);
}
.body-div input {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
#file-upload,
#img-upload {
  display: none;
}

.modal-upload-div {
  height: 141px;

  background: rgba(246, 246, 246, 0.5);

  border: 1px dashed var(--inputBorder-color);
  border-radius: 5px;
}
.modal-upload-div label {
  cursor: pointer;
}

.modal-bottom-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #3c3c46;
}
#momentModal .save-btn {
  width: 165px;
  height: 45px;
  color: white;
  background: var(--inputBorder-color);
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
#momentModal .save-btn-disable {
  width: 165px;
  height: 45px;
  color: white;
  background: gray;
  border-radius: 5px;
  cursor: not-allowed !important;
}
#momentLinkModal .save-btn {
  width: 165px;
  height: 45px;
  color: white;
  background: var(--inputBorder-color);
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
.save-btn {
  width: 165px;
  height: 45px;
  color: white;
  background: var(--inputBorder-color);
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
.moment-resource-container {
  max-width: 100%;
  overflow-x: scroll;
}
.moment-resource-container img {
  width: 30px;
  height: 30px;
}

.moments-page .checkbox {
  width: 24px;
  height: 24px;
  background: #ffffff;

  border: 1px solid var(--inputBorder-color);
  border-radius: 2px;
}

.moments-table-head {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--inputBorder-color);
}
.moments-table-head tr {
  border-style: hidden;
}
.moments-table-head th {
  text-align: left;
}

.moments-table-body tr {
  height: 72px;
}
.moments-table-body td {
  vertical-align: middle;
  text-align: left;
}
.moments-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: var(--inputBorder-color);
}

.moments-page .edit-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--inputBorder-color);
}
.moments-page .cancel-btn {
  width: 127px;
  height: 45px;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
.moments-page .update-btn {
  width: 127px;
  height: 45px;
  background: var(--inputBorder-color);
  color: white;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
.moments-page .create-moment-btn {
  width: 170px;
  height: 45px;
  background: var(--inputBorder-color);
  color: white;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}

.moments-page .female-img-big {
  width: 33px;
  height: 33px;
}
.moments-page .female-img-sm {
  width: 27px;
  height: 27px;
}

.moments-page .user-input-div {
  width: 160px;
  height: 95px;
  background: #f9f9f9;
  border: 1px dashed var(--border-color);
  border-radius: 4px;
}
.moments-page .user-input-div img {
  width: 30px;
  height: 30px;
}
*/