@import url("https://use.typekit.net/ceh2jwg.css");

* {
  font-family: "aktiv-grotesk", sans-serif;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

:root {
  --background-color: #F9F9F9;
  --border-color: #D4D7DA;
  --inputBorder-color: #1D1E24;
  --primaryGreen-color: #9FE870;
  --bgLight-color: #FFFFFF;
  --bgDark-color: black;


  --text-color: #10161d;
  --white-color: #fff;
  --grey-text: #A6ABAF;
  --borders: #D2D6D9;
  --primary-font: "aktiv-grotesk", sans-serif;

  --primary-color: #161719;
  --secondary-color: #9fe870;
  --secondary-color-N: #AF144B;
  --color1: #960528;
  --color2: #aa052d;
  --color3: #be0028;
  --color4: #dc0032;
  --color5: #f52d28;
  --color6: #fa551e;
  --color7: #ff780f;
  --color8: #f0325a;
  --color9: #af144b;
  --color10: #870a3c;
  --color11: #640032;
  --color12: #500a28;
  --bs-link-color: red;
  --xlight-grey: #f6f6f6;
  --light-grey: #e4e5e7;
  --medium-grey: #a09fa1;
  --dark-grey: #232529;
  --spacing-xsmall: 0.5rem;
  --spacing-small: 1rem;
  --spacing-medium: 1.5rem;
  --spacing-large: 2.5rem;
}

body {
  font-size: 16px !important;
}

/* body{
  background: var(--background-color) !important;
} */
.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: var(--bgDark-color) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.color-darkGray {
  color: var(--inputBorder-color);
}

.mt-80 {
  margin-top: 70px;
}

div[role="tablist"] button {
  color: #1D1E24;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--primary-color);
}

/* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
    
  }



.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999 !important;
  background: radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.4));
}

.loaderImage-container {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%);
}

.non-clickable {
  pointer-events: none;
}



/* dark mode */
.persona-container-dark {
  background-color: black;
  min-height: 100vh;
}

.persona-heading-dark {
  color: white;
}

.modal-close-btn {
  color: white !important;
}

/* light mode */
.persona-container-light {
  background-color: #fff;
  margin-top: 3.25rem;
}

.persona-heading-light {
  color: black;
}




/* SPLIT PAGE */
.mainContainer {
  display: flex;
  min-height: calc(100vh - 7.875rem);
}

.mainContainer-left {
  min-height: calc(100vh - 3.25rem);
  flex: 0 0 14rem;
  padding: 1rem 1rem;
  border-right: 2px solid var(--primary-color);
}

.mainContainer-right {
  flex: 1;
  padding: 2rem 2rem;
  justify-content: center;
}

.mainContainer-left .personaNav {
  flex-direction: column;
}

@media only screen and (max-width:900px) {
  .mainContainer-left {
    display: none
  }

  .mainContainer-right {
    padding: 2rem 1rem;
  }
}

/* FORMS */
.form-field {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 0.375rem;
}

.form-control:hover,
.form-control:focus {
  border: 2px solid var(--primary-color);
}

/* BUTTONS */
.btn {
  border-radius: 0.375rem;
}

.btn-primary {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: #333;
  border-color: #333;
  color: #fff
}

.btn-outline-primary {
  padding: 0.5rem 1rem;
  border-color: var(--primary-color);
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.dropdown-menu {
  border: 2px solid var(--primary-color)
}

.btn-group .btn {
  padding: 0.375rem 1rem;
}

.dropdown-menu {
  padding: 0
}

.dropdown-item {
  padding: 0.5rem 1rem;
  cursor: pointer
}

.dropdown-item.disabled {
  cursor: default;
}

.dropdown-item:focus {
  background-color: #10161d;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.form-label {
  margin-bottom: 0.25rem;
}

h1 {
  font-size: 1.75rem;
  margin-top: 0;
}

.swal2-title{
  /* font-size: 1.4rem !important; */
  color: black !important;
  font-weight: 400 !important;
}
.swal2-styled.swal2-confirm {
  background-color: black !important; /* Change to your desired background color */
  color: white !important; /* Change to your desired text color */
  border: 1px solid black !important; /* Remove the default button border */
  border-radius: 5px !important;
  padding: 8px 16px !important;
  font-weight: 300 !important;
}

.swal2-styled.swal2-confirm:hover {
  background: black !important;
}
.disable-btn {
  cursor: not-allowed !important;
}

