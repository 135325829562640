.emissaryChat {
  display: flex;
  min-height: calc(100vh - 3.25rem);
}

.emissaryChat-list {
  flex: 0 0 16rem;
  border-right: 2px solid var(--primary-color);
}

.emissaryChat-chatbox {
  flex: 1;
  min-height: calc(100vh - 3.25rem);
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mb-30 {
  margin-bottom: 2rem;
}

/********************************
CHAT HEADER
********************************/
.chatHeader {
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid var(--border-color);
  height: 4.2rem;
}

.chatHeader-inner {
  max-width: 55rem;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.moreBtn {
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.25rem;
  text-align: center;
  padding: 0;
}

.moreBtn i {
  position: relative;
  top: 6px;
}

.moreBtn:hover,
.moreBtn:focus {
  color: #fff;
}

.test {
  display: flex;
  align-items: flex-start;
}

/********************************
CHAT INPUT
********************************/
.chat-input {
  background: #fff;
  /* height: 5.3125rem;
  max-height: 10rem; */
  position: absolute;
  bottom: 0;
  z-index: 999;
  border-top: 1px solid var(--border-color);
  width: 100%;
}

.chatInput-inner {
  position: relative;
  max-width: 55rem;
  /* max-height: 10rem; */
  margin: 0 auto;
  padding: 1rem;
  display: Flex;
  width: 100%;
}
.chat-question {
  padding-right: 1rem;
  max-width: 100%;
  width: 100%;
}

/********************************
PERSONA CHAT ACTIONS
********************************/

.chatHistory {
  /* width: 20rem; */
  max-height: calc(100vh - 13rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.3rem;
}

.chatHistory_item {
  padding: 0.75rem 1rem;
  position: relative;
  line-height: 1rem;
  margin-bottom: 0.125rem;
  cursor: pointer;
  /* padding-right: 3.5rem; */
  /* width: 19.5rem; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  /* border: 1px solid black; */
  margin-bottom: 8px;
  border-radius: 5px;
}
.chatItem-primary {
  width: 100%;
  display: flex;
  align-items: center;
}
.chatItem-primary span {
  margin-left: 7px;
}
.mind-link {
  width: 100%;
}

.mainContainer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh-3, 25rem);
}
.mainContainer-left .chatHistory {
  width: 18rem;
  max-height: calc(100vh-3, 25rem);
}

.mainContainer-left .chatHistory_item {
  width: 100%;
  font-size: 1rem;
  align-items: center;
  display: flex;
  height: 45px;
}

.chatHistory_item:hover,
.chatHistory_item.active {
  background: var(--xlight-grey);
}

.chatHistory_buttons {
  position: absolute;
  top: 0.75rem;
  right: 0.25rem;
  display: none;
}

.chatHistory_item:hover .chatHistory_buttons {
  display: flex;
}
.chatHistory_item:hover .chat-title {
  width: 75%;
}

.chatHistory-btn {
  width: 1.5rem;
  height: 2rem;
  line-height: 2.25rem;
  text-align: center;
  transition: 0.2s;
}

.chatHistory-btn:hover {
  color: var(--secondary-color);
}

.chatHistory-btn i {
  font-size: 1rem;
}
.edit-title {
  width: 80% !important;
}
.dropdown-menu h3 {
  padding: 1rem 1rem 1rem 1rem;
  line-height: 1rem;
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
}

/********************************
PERSONA CHAT ACTIONS
********************************/

.chat-row {
  background: #fff;
  margin-bottom: 1rem;
  position: relative;
}

.chat-rows {
  height: calc(100vh - 13rem);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 55rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  /* flex:1; */
  width: 100%;
}

.chatResponseContainer {
  display: flex;
  padding: var(--spacing-medium) var(--spacing-large) var(--spacing-medium)
    var(--spacing-small);
  align-items: flex-start;
}

.chatResponse {
  /* padding-top: 0.375rem */
}

.chatResponse p:last-of-type {
  margin-bottom: 0;
}

.chatResponse p:empty {
  display: none;
}

.chatResponse a {
  text-decoration: none;
  color: var(--primary-color);
}

.chatResponse a:hover {
  text-underline-offset: 0.313rem;
  text-decoration: underline;
  text-decoration-color: var(--light-grey);
}

.chat-row .avatar {
  flex: 0 0 2rem;
  border-radius: 50%;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
}

.newChatBtn {
  width: 100%;
  margin-bottom: 2rem;
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #21dc82;
  border-color: #21dc82;
}
.newChatBtn span {
  margin-right: 10px;
}
.newChatBtn:hover {
  background: black;
}
.newChatBtn i {
  position: relative;
  top: 1px;
}

/********************************
PERSONA CHAT ACTIONS
********************************/
.chat-row-action-buttons {
  position: absolute;
  top: 0.35rem;
  right: 0.25rem;
  background: #fff;
  display: flex;
  align-items: center;
}

.chat-row-action-buttons i {
  font-size: 1rem;
  padding: 0.125rem;
  cursor: pointer;
}

.chat-row-action-buttons i.disabled {
  cursor: default;
  opacity: 0.2;
}

.chat-row .icon-mind {
  color: var(--secondary-color);
  cursor: default;
  font-size: 1.125rem;
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
}
.moment-section {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
/********************************
TOGGLE BUTTONS
********************************/
.chat-container {
  all: unset;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.all-btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.single-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.chat-dropdown {
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
  width: 240px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--inputBorder-color);
}

.chat-dropdown:disabled {
  display: none;
}

.chat-section .chat-input input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}

.chat-section .chat-input {
  position: relative;
  border-top: 1px solid var(--border-color);
}

.send-btn {
  position: absolute;
  right: 1.5rem;
  bottom: 1.3rem;
}
/* .chat-section .chat-input .send-btn .chat-send {
  right: 8px;
  bottom: 11px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--primaryGreen-color);
  cursor: pointer;
} */

.hide-desktop {
  display: none;
}

@media only screen and (max-width: 900px) {
  .hide-desktop {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .chatHeader {
    height: auto;
  }
}

.chat-send {
  width: 2.3rem;
  height: 2.3rem;
  background-color: #21dc82;
  border-radius: 100%;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-chat-button {
  opacity: 0.5;
  cursor: not-allowed;
  width: 2.3rem;
  height: 2.3rem;
  background-color: #87b19d;
  cursor: not-allowed;
  border-radius: 100%;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendBtnImg {
  width: 1.3rem;
  height: 1.3rem;
}
.chat-send:hover {
  cursor: pointer;
}

.chat-tab .edit-img {
  width: 18px;
  height: 18px;
}

.chat-tab .mind-img img {
  width: 29px;
  height: 29px;
}

.chat-tab .brendan-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.chat-tab .trash-img {
  width: 18px;
  height: 18px;
}

.chat-input input {
  padding-right: 50px !important;
}

.chat-input-loader {
  height: 35px;
  /* position: absolute; */
  right: 8px;
  bottom: 12px;
  width: 35px;
}

.chat-title {
  width: 100%;
  white-space: nowrap;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-title-del {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cross:hover {
  color: red !important;
}

.tick:hover {
  color: rgb(51, 136, 51) !important;
}
.icon-copy {
  margin-right: 3px;
}

/* media query */
@media (max-width: 767px) {
  .test {
    display: flex;
    flex-direction: column;
  }
  .select-moment-dropdown {
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .chatHeader-inner {
    justify-content: center;
  }

  .chat-header {
    flex-direction: column;
  }
}

/* typewriter effect */

.typed {
  line-height: 35px;
  width: 80%;
  text-align: left;
  /* font-family: "Courier New", "Lucida Console", monospace; */
  vertical-align: text-bottom;
}

.typed::after {
  content: "|";
  font-size: 150%;
  line-height: 30px;
  animation: blink 0.75s step-end infinite;
}

/* custom text area */
.custom-textarea {
  /* border: 2px solid #1d1e24;
  width: 100%;
  line-height: 1rem;
  border-radius: 5px;
  padding: 1rem;
  max-height: 200px;
  overflow-y: scroll;
  height: 3.25rem !important */
  resize: none;  
  border: 1px solid #1d1e24;
  width: 100%;
  height: 3rem;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 50px;
  padding-block: 10px;
  /* min-height: 3rem; */
  max-height: 220px;
  line-height: 1.7;
  overflow: hidden;
  /* overflow-y: auto; */
}

/* .custom-textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
} */
@keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: rgba(245, 245, 245);
  }
}
