.userHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #fff;
    height: 4.5rem;
    border-bottom: 2px solid var(--primary-color)
}

.user-header {
    display: flex;
    align-items: start;
}

.user-header-img img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
}

.name-user {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: var(--primary-color);
    margin-bottom: 0.25rem;
}

.userHeader-info {
    align-items: start;
    margin-left: 1rem;
    display: flex;
}
.userHeader-info span{
    padding-left: 10px;
}

.user-designation {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--primary-color);
    margin-bottom: 0 !important;
}

.userHeader .personaNav {
    display: none !important
}

@media only screen and (max-width:900px) {
    .userHeader .btn-primary {
        display: none;
    }
}