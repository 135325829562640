.userprofile-container{
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
.close-icon {
    cursor: pointer;
    position: absolute;
    top: 1.3rem;
    right: 1.2rem;
    font-size: 1.3rem;
}
.userprofile {
    width: 60%;
}
.userprofile h1 {
    margin-bottom: 1rem;
}
.upload-desc {
    font-size: 14px;
    color: #3C3C46;
}
.upload-heading {
    margin-bottom: 2px !important;
    font-size: 15px;
    font-weight: 600;
}

/* subscriptions styles */
.subscription-container {
    /* width: 80%; */
}
.subscription-container h1{
font-size: 1.3rem;
font-weight: 600;
}
.renewal-amount{
    font-size: 2.5rem;
    color: #21DC82;
}
.renewal-amount span {
    text-decoration: line-through;
    text-decoration-color: #21DC82;
    color: #000;
}