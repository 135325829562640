.bottom-right-button {
  position: fixed;
  bottom: 0px;
  right: 0;
  background-color: #000;
  color: white;
  border: none;
  font-size: 0.9rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 3px;
  padding: 5px 16px;
  cursor: pointer;
  z-index: 99999 !important;
}

.not-fixed-button {
  background-color: #000;
  color: white;
  border: none;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 5px 16px;
  cursor: pointer;
  height: 35px;
}
