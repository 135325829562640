.heading {
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; */
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .upload-img {
    /* border: 2px solid black; */
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* margin-left:500px; */
  }
  .form-input {
    margin-left: 100px;
  }
  .form-control {
    width: 50%;
    height: 50px;
  }
  .red-star {
  color: red;
  margin-left: 4px;
}

.upload-div {
  border: 2px dashed rgb(163, 161, 161);
  /* width: 400px; 
  height: 100px;
  margin-top: 50px; */
}

.upload-div img {
  width: 30px;
  height: 30px;

}

/* .upload-div h5{
  width: 130px;
} */
#file-upload {
  display: none;
}
.upload-file{
  padding-top: 30px;
  padding-left: 100px;
}

.front-page .save-btn {
  margin-left: 600px;
}
.next-btn {
  margin-left: 20px;
}