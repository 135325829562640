.mainHeader {
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:0 1rem;
  height: 3.25rem;
  position:fixed;
  top:0;
  width:100%;
  left:0;
  background:var(--primary-color);
  z-index:99
}
.mainHeader .dropdown-toggle::after {
  display:none;
}
.emissaryLogo {
  /* width:1.5rem;
  height:1.5rem; */
}
.brook-img {
  width:2rem;
  height:2rem;
}
.hideToggle {
  display:none;
}
.user-name {
  font-weight: 600;
  /* color: white; */
}

/* toggler css */
.checkbox {
  opacity: 0;
  position: absolute;
}
.label:hover {
  cursor: pointer;
}

.label {
  width: 50px;
  height: 23px;
  background-color: #0071bc;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.4);
}

.ball {
  width: 18px;
  height: 18px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: white;
}

.fa-sun {
  color: white;
  font-size: 12px;
}

/* ligght theme */
.app-navbar-light {
  background-color: black;
}
.persona-link-light {
  color:black 
}
.header-username-light {
  color:black 
}
.header-username-light:hover{
  color: black;
}
/* dark theme */
.app-navbar-dark {
  
  background-color: #F9F9F9;
}
.persona-link-dark {
  color:white 
}
.header-username-dark {
  color:white 
}
.header-username-dark:hover {
  color: white;
}
