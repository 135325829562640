.moments-table {
  font-family: "aktiv-grotesk", sans-serif !important;
}
.mind-row {
  border-bottom:2px solid var(--primary-color);
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.mind-row > div {
  padding:1rem;
  padding-left:0
}

.mind-row .cursor-pointer img {
  width:1rem
}

.mindContainer {
  max-width:100%;
}

.mindContainer h1 {
  text-align: left;
  margin-bottom:1rem;
  margin-top:0;
}
.show-on-desktop {
  display: none !important;
}

@media only screen and (min-width:900px) {
  .show-on-desktop {
    display: flex !important;
  }
}


.mind-page .female-img{
  width: 33px;
  height: 33px;
}
.mind-page .edit-img{
  width: 18px;
  height: 18px;
}

.no-moments{
  font-family: 'Indivisible'
}