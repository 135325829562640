.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}

.persona-container {
  max-width: 123.75rem;
  margin: 0 auto;
  padding: var(--spacing-medium);
}

#file-upload,
#img-upload {
  display: none;
}

.personaUpload-document {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  background: #f6f6f6;
}

.create-persona-container {
  width: 100%;
}
.inner-wrapper {
  width: 50%;
}
.personaForm {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1.3rem;
  width: 100%;
}
.create-persona-input {
  height: 45px;
  width: 100%;
  border: 1.5px solid #1d1e24;
  border-radius: 5px;
  padding-inline: 0.4rem;
}
.upload-image-container {
  width: 100%;
  font-family: "aktiv-grotesk", sans-serif !important;
}

@media only screen and (max-width: 1000px) {
  .inner-wrapper {
    width: 70%;
  }
  .dropdown-container {
    width: 90%;
  }
}
@media only screen and (min-width: 1200px) {
  .dropdown-container {
    width: 70%;
  }
}


@media only screen and (max-width: 767px) {
  .inner-wrapper {
    width: 100%;
  }
  .personaForm {
    grid-template-columns: 1fr;
  }
  .dropdown-container {
    width: 100%;
  }
}

.personaForm .full-width {
  grid-column: 1 / span 2;
}
