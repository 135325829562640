/********************************
GRID
********************************/

.emissary-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.2rem;
  column-gap: 1.2rem;
  /* max-height: 70vh;
  overflow-y: auto; */
  /* overflow-x: hidden; */
  /* padding-right: 1rem; */
}
@media only screen and (max-width: 1300px) {
  .emissary-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 800px) {
  .emissary-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .emissary-grid {
    grid-template-columns: 1fr;
  }
}
/********************************
CARDS
********************************/

.emissary-card {
  padding: var(--spacing-medium);
  padding-bottom: var(--spacing-small);
  background: #fff;
  border: 2px solid var(--primary-color);
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.emissary-card:hover {
  box-shadow: 6px 6px var(--primary-color);
}
.emissary-cardHeader {
  display: flex;
  justify-content: space-between;
}
.dropdown-toggle::after {
  display: none;
}
.emissary-cardHeader .icon {
  width: 2.25rem;
  height: 2.25rem;
  background: pink;
}
.emissary-cardHeader i {
  font-size: 1.25rem;
}
.emissary-cardHeader .avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.avatar:hover {
  cursor: pointer;
}
.emissary-cardHeader .avatar img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.emissary-cardBody {
  padding: var(--spacing-small) 0 0 0;
}
.emissary-cardBody h2 {
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom:0.25rem;
}
.emissary-cardBody h2:hover {
  opacity: 0.8;
}
.emissary-cardBody p {
  margin-bottom: 0;
}
.user-email {
  margin-top: 1rem;
}
.emissary-cardFooter {
  border-top: 1px solid #cacaca;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emissary-cardFooter img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
/********************************
BUTTONS
********************************/
.divider {
  height: 1px;
  width: 100%;
  background: #cacaca;
  margin: var(--spacing-medium) 0;
}
.emissary-container .left-column .btn-primary {
  width: 100%;
}
.persona-container {
  max-width: 123.75rem;
  margin: 0 auto;
  padding: var(--spacing-large) var(--spacing-medium);
}

.create-persona-link {
  border: 2px solid black;
  width: 200px;
  height: 50px;
}

.create-persona-link:hover {
  color: black;
}

.all-personna-div h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
}

.all-persona-btn {
  border: 2px solid black;
  margin: 50px 0px 0px 500px;
  width: 20%;
  height: 40px;
}

.all-persona-btn h5 {
  margin-top: 10px;
  margin-left: 30px;
}

.persona-text-container p {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  max-width: 641px;
}

.create-btn-container {
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}

.create-btn-container a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.all-personna-div input:focus {
  outline: none;
}

.filter {
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}
.search-input {
  height: 40px;
  background: #ffffff;
}
.persona-table-head {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--inputBorder-color);
}
.persona-table-head tr {
  border-style: hidden;
}
.persona-table-head th {
  text-align: left;
}
.persona-table-body tr {
  height: 72px;
  width: 500p;
}
.persona-table-body td {
  vertical-align: middle;
  text-align: left;
}
.persona-table-body .company-td {
  font-weight: 400;
}
.persona-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: var(--inputBorder-color);
}

.search-img {
  width: 18px;
  height: 18px;
}

.brendan {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.female {
  width: 40px;
  height: 40px;
}
.persona-dot-options::marker {
  content: "";
}
.persona-dot-options a::after {
  display: none;
}
.persona-dot-options .nav-dropdown {
  bottom: 0;
}
.profile-image {
  width: 200px;
  height: 200px;
}
.profile-image:hover {
  cursor: pointer;
}
#file-upload,
#img-upload {
  display: none;
}
.swal2-popup {
  z-index: 9999; /* Set a higher z-index value to make SweetAlert2 popups appear above other elements */
}
.swal2-container {
  z-index: 1500 !important;
}
