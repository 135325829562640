.signupContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
}

.signupContainer-inner {
  max-width: 35rem;
  width: 100%;
  padding-inline: 3rem;
  padding-block: 1rem;
  background: #fff;  
  box-shadow: 0 12px 40px rgba(0,0,0,0.12);
}
.create-account-btn {
  background: black !important;
  color: white;
  font-weight: 400 !important;
  /* width: 100%; */
}
.signupContainer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 2rem

}

.signupContainer-header h1 {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-weight:600
}

.logo {
  width: 8rem;
  /* height: 4.5rem; */
}

.signupContainer-form {
}

.signupContainer-form p {
  text-align: center;
}

.form-control {
  width: 100%
}
.signup-link {
  color:black;
  font-weight: 600;
}
.signup-link:hover {
  cursor: pointer;
}
@media only screen and (max-width: 44rem) {
  .signupContainer {
    background: #fff;
  }

  .signupContainer-inner {
    padding: 1rem;
  }
}