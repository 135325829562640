.personaNav {
  display: flex;
}

.personaNav-item {
  margin-bottom: 0.5rem;
  text-decoration: none;
}
.myclass {
  color: blue;
}
.notallowed-link{
  width: fit-content;
}
.notallowed-item {
  padding: 0.75rem;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1rem;
  width: fit-content;
  display: block;
}
.personaNav-item .item {
  padding: 0.75rem;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1rem;
  border: 1px solid #fff;
  border-radius: 0.375rem;
  position: relative;
  display: block;
}

.personaNav-item .item i {
  margin-right: 0.25rem;
  font-size: 16px;
}

.personaNav-item .item:hover,
.personaNav-item .item:focus,
.personaNav-item .item.current {
  border: 1px solid var(--primary-color);
}

i.current {
  color: var(--secondary-color);
}
.admin-item {
  padding: 0.75rem;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid #fff;
  border-radius: 0.375rem;
}
.admin-item:hover,
.admin-item:focus,
.admin-item.current {
  border: 1px solid var(--primary-color);
}
@media only screen and (max-width: 900px) {
  .hide-desktop {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .show-desktop {
    display: flex;
  }
}

.togglebtn1 {
  border: 2px solid #000;
  background: #fff;
  border-radius: 5px;
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2rem;
}

.dropdown-item a {
  color: var(--primary-color);
  text-decoration: none;
}
