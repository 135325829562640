.buGObk {
  border-bottom-color: #3c3c46 !important;
  min-height: 40px !important;
  border-bottom-width: 2px !important;
}
.VqMBs {
  padding-bottom: 10px !important;
}
.css-1nur6r0 {
  border: 1.7px solid black !important;
}
/* usertable.css */

.table-container {
  max-width: 75vw;
  overflow-x: auto;
}

/* mobile devices */
/* usertable.css */

@media (max-width: 500px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}
@media (max-width: 1200px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}
/* ant table styling */

.ant-table-thead > tr > th {
  background-color: transparent !important;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-190m0jy).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none !important;
}

.bMuwEY {
  overflow-y: visible !important;
  overflow-x: visible !important;
}